import axios from "axios";
const state = {
    inventories:[],
    loader: true,
    report:undefined,
    report_loader:true
};
const getters = {};

const actions = {
    getInventories( {commit, state} ){
        state.loader = true
        axios
        .get(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/inventory/all")
        .then(response => {
            commit('setInventories', response.data);
        });
    },
    modifyInventories({commit}, item){
        commit('addInventoryMovement', item);
    },
    getInventoryReport( {commit, state}, filters ){
        state.report_loader = true
        console.log('perro')
        axios
        .get(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/inventory_report" + (filters!=undefined?filters:''))
        .then(response => {
            const inventory = response.data
            const report = [inventory.reduce((acumulador, objeto) => {
                acumulador.total_inventory += objeto.inventory;
                acumulador.total_cost += objeto.inventory_cost;
                return acumulador;
              }, { 
                total_cost : 0,
                total_inventory : 0,
            })].map(inv=>{return{
                total_cost:inv.total_cost.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                total_inventory:inv.total_inventory.toFixed(2),
                avg_cost: (inv.total_cost/(inventory.length)).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                avg_inventory: (inv.total_inventory/(inventory.length)).toFixed(2)
            }})[0];

            console.log(report)

            commit('setInventoryReport', report);
        });
    },
};

const mutations = {
    setInventories(state, data){
        state.inventories = data;
        state.loader = false;
    },
    addInventoryMovement(state, data){
        state.inventories.unshift(data)
    },
    setInventoryReport(state, data){
        state.report = data;
        state.report_loader = false;
    },
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}